import { Form } from "antd";
import Input from "antd/lib/input";
import Checkbox from "antd/lib/checkbox/Checkbox";

const TokenDetails = ({
  onInputNumberChange,
  canBeMintableBurnable,
}: {
  onInputNumberChange: (event: any) => void;
  canBeMintableBurnable: boolean;
}) => {
  return (
    <div>
      <div className="green-title-container">
        <h3 className="gradient-background-text">Token </h3>
        <h3>Details</h3>
      </div>
      <div className="token-details-container content-box">
        <p>Please enter some basic information about your new token. </p>
        <div>
          <div className="token-type-container">
            <label className="token-type-label">Token Type</label>
            <div className="token-type-checkboxs-container">
              <Form.Item name="mintable" valuePropName="checked">
                <Checkbox disabled={!canBeMintableBurnable}>Mintable</Checkbox>
              </Form.Item>
              <Form.Item name="burnable" valuePropName="checked">
                <Checkbox disabled={!canBeMintableBurnable}>Burnable</Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="details-inputs-grid">
          <div className="input-container">
            <label>Token Name</label>
            <Form.Item
              name="tokenName"
              rules={[{ required: true, message: "Token Name is required!" }]}
            >
              <Input size="large"></Input>
            </Form.Item>
            <p>Create a name for your token. (Alphanumeric characters only.)</p>
          </div>

          <div className="input-container">
            <label>Token Symbol Ticker</label>
            <Form.Item
              name="tokenSymbol"
              rules={[
                { required: true, message: "Token Symbol Ticker is required!" },
              ]}
            >
              <Input size="large"></Input>
            </Form.Item>
            <p>
              Create a unique symbol ticker for your token. (Alphanumeric
              characters only.)
            </p>
          </div>

          <div className="input-container">
            <label>Total Token Supply</label>
            <Form.Item
              name="initialSupply"
              rules={[
                {
                  required: true,
                  message: "Total token supply is required!",
                },
                {
                  max: 1000000000,
                  message: "Maximum supply reached!",
                },
              ]}
            >
              <Input
                onChange={onInputNumberChange}
                className="token-supply-input"
                size="large"
              ></Input>
            </Form.Item>
            <p>
              Enter the total supply of your new token. All tokens created on
              contract deployment will be transferred to the owner’s wallet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenDetails;
