import {
  BytesLike,
  Contract,
  ContractFactory,
  ContractInterface,
  Signer,
} from "ethers";

export type CustomDataJSON = {
  abi: ContractInterface;
  bytecode:
    | BytesLike
    | {
        object: string;
      };
};
export const deployContract = async (
  tokenSupply: any,
  tokenName: string,
  tokenSymbol: string,
  adminAccount: string,
  recoveryAccount: string,
  lockPeriod: number,
  signer: Signer,
  controllerAddress: string,
  contractJson: CustomDataJSON
): Promise<Contract> => {
  const factory = new ContractFactory(
    contractJson.abi,
    contractJson.bytecode,
    signer
  );

  const contract = await factory.deploy(
    tokenSupply,
    tokenName,
    tokenSymbol,
    adminAccount,
    recoveryAccount,
    lockPeriod,
    controllerAddress
  );

  return contract;
};
