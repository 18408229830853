import { ExclamationCircleFilled } from "@ant-design/icons";
export const ERROR = "ERROR";

const Confirmation = ({ state }: { state: string }): JSX.Element | null => {
  if (state === ERROR) {
    return (
      <div className="error-container">
        <div className="error-content">
          <ExclamationCircleFilled className="icon" />
          There was an error deploying your token!
          <br />
          Please review your token information and try again.
        </div>
      </div>
    );
  }
  return null;
};

export default Confirmation;
