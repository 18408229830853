// ANTD
import { Collapse } from "antd";

// STYLES
import "./styles.scss";

// IMAGES
import arrowDown from "../../assets/images/arrow-down.svg";
import arrowUp from "../../assets/images/arrow-up.svg";

const { Panel } = Collapse;

const Faq = () => {
  return (
    <div id="faqs" className="faq-container">
      <div className="green-title-container">
        <h3 className="gradient-background-text">Frequently </h3>
        <h3>Asked Questions</h3>
      </div>
      <div className="content-box faq-column-container ">
        <Collapse
          className="faq-collapse"
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img src={isActive ? arrowUp : arrowDown} alt="" />
          )}
          bordered={false}
        >
          <Panel header="What is an ERC20 Token?" key="1">
            <p>
              ERC20 is a widely-recognised token standard designed for the
              Ethereum blockchain. All ERC20 tokens share a list of rules that
              they must follow. A uniform technical specification helps to
              ensure that all new tokens implemented through smart contracts
              will function appropriately within the ecosystem.{" "}
              <a
                href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/"
                target="_blank"
                rel="noreferrer"
              >
                Read more
              </a>
            </p>
          </Panel>
        </Collapse>

        <Collapse
          className="faq-collapse"
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img src={isActive ? arrowUp : arrowDown} alt="" />
          )}
          bordered={false}
        >
          <Panel header="Can I try L-ERC20 on a Testnet first?" key="1">
            <p>
              Yes, absolutely. Please select the ‘Ropsten’ network on
              deployment, and we will take care of everything for you. Just be
              sure to switch your network in MetaMask too.
            </p>
          </Panel>
        </Collapse>

        <Collapse
          className="faq-collapse"
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img src={isActive ? arrowUp : arrowDown} alt="" />
          )}
          bordered={false}
        >
          <Panel header=" What happens after my new token is deployed?" key="1">
            <p>
              After you approve the transaction using MetaMask, you will receive
              the transaction hash, which you can follow on{" "}
              <a href="https://etherscan.io/" target="_blank" rel="noreferrer">
                EtherScan
              </a>
              . Network conditions vary, so you may need to give it some time.
              After the transaction is confirmed, you will be able to add your
              freshly minted token to MetaMask.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};
export default Faq;
