import { useState } from "react";

// ANTD
import { FormInstance, Modal } from "antd";

// STYLES
import "./styles.scss";

// INTERFACES
import { Chain } from "../../config/networks";
import Loader from "./Loader";
import Confirmation from "./Confirmation";
import { CONFIRM, CONFIRM_MODAL_TITLE, PLEASE_CONFIRM } from "../../constants";
import ModalContent from "./ModalContent";
import { gaEvent } from "../../utils/ga";

interface DeployModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (modalState: boolean) => void;
  form: FormInstance<any>;
  chain: Chain | null;
}

const DeployModal = ({
  isModalVisible,
  setIsModalVisible,
  form,
  chain,
}: DeployModalProps) => {
  const [deployState, setDeployState] = useState<string>(CONFIRM);
  const [modalTitle, setModalTitle] = useState<string>(PLEASE_CONFIRM);
  const hideModal = (): void => {
    setIsModalVisible(false);
    setDeployState(CONFIRM);
    setModalTitle(CONFIRM_MODAL_TITLE);
  };

  const handleOk = (): void => {
    gaEvent("Deploy Initialized");
    setIsModalVisible(false);
    setDeployState(CONFIRM);
    setModalTitle(CONFIRM_MODAL_TITLE);
  };

  const handleCancel = (): void => {
    gaEvent("Deploy Canceled");
    setIsModalVisible(false);
    setDeployState(CONFIRM);
    setModalTitle(CONFIRM_MODAL_TITLE);
  };

  return (
    <Modal
      className="deploy-modal-container"
      title={modalTitle}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Deploy"
      footer={null}
      closable={false}
      width="70%"
      centered
    >
      <ModalContent
        state={deployState}
        form={form}
        chain={chain}
        setDeployState={setDeployState}
        hideModal={hideModal}
        setModalTitle={setModalTitle}
      ></ModalContent>
      <Loader state={deployState}></Loader>
      <Confirmation state={deployState}></Confirmation>
    </Modal>
  );
};
export default DeployModal;
