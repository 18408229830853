export const formatAddress = ({
  address,
  fullSize = false,
}: {
  address: string | null | undefined;
  fullSize?: boolean;
}): string => {
  if (fullSize && address) {
    return `${address}`;
  } else if (address) {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4,
      address.length
    )}`;
  } else {
    return "N/A";
  }
};
