import { SyncOutlined } from "@ant-design/icons";
import {
  CHAINSULTING_LEAD,
  HACKEN_LEAD,
  LOADING,
  VERIFYING_SOURCE,
} from "../../../constants";

const Loader = ({ state }: { state: string }): JSX.Element | null => {
  if (
    state === LOADING ||
    state === VERIFYING_SOURCE ||
    state === HACKEN_LEAD ||
    state === CHAINSULTING_LEAD
  ) {
    return (
      <div className="loading-container">
        <SyncOutlined className="loading-icon" twoToneColor="#52c41a" spin />
        {state === LOADING ? "Deploying ..." : ""}
        {state === VERIFYING_SOURCE ? "Verifying source code ..." : ""}
        {state === HACKEN_LEAD ? "Sending Hacken Email ..." : ""}
        {state === CHAINSULTING_LEAD ? "Sending email to Chainsulting ..." : ""}

        <br />
        <span>Please be patient</span>
      </div>
    );
  }
  return null;
};

export default Loader;
