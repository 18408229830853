import { Chain, networks } from "../config/networks";

export const getChain = (id: number | string): Chain => {
  if (typeof id === "string") {
    return networks.filter(
      (network) => parseInt(id) === parseInt(network.id)
    )[0];
  }
  return networks.filter((network) => id === parseInt(network.id))[0];
};
