import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import reportWebVitals from "./reportWebVitals";

// STATE
import { accountVar, chainVar, signerVar } from "./state";

// COMPONENTS
import App from "./App";

// STYLES
import "./index.scss";

import { Web3ReactProvider } from "@web3-react/core";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import type {
  ExternalProvider,
  JsonRpcFetchFunc,
} from "@ethersproject/providers";
import { Web3Provider } from "@ethersproject/providers";
function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  return new Web3Provider(provider);
}

if ((window as any)._env_.REACT_APP_ENVIRONMENT !== "DEVELOPMENT") {
  Sentry.init({
    dsn: (window as any)._env_.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: (window as any)._env_.REACT_APP_ENVIRONMENT,
  });
}

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        account: {
          read() {
            return accountVar();
          },
        },
        chain: {
          read() {
            return chainVar();
          },
        },
        signer: {
          read() {
            return signerVar();
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  uri:
    (window as any)._env_?.REACT_APP_BACKEND_URL ||
    "http://localhost:3001/graphql",
  cache,
});

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
