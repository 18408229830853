import { Contract } from "ethers";
import { ga } from "react-ga";

export const deploymentGaEvent = (contract: Contract, chainName: string) => {
    gaEvent("Token Deployed", `${contract.deployTransaction.hash} - ${chainName}`);
}

export const gaEvent = (category: string, label?: string) => {
    ga("send", {
      hitType: "event",
      eventCategory: category,
      eventLabel: label,
    });
}