import { gql } from "@apollo/client";

export const GET_ACCOUNT = gql`
  query GetAccount {
    account @client
  }
`;

export const GET_CHAIN = gql`
  query GetChain {
    chain @client
  }
`;

export const GET_CONNECT_MODAL = gql`
  query GetConnectModal {
    connectModal @client
  }
`;

export const GET_PROVIDER = gql`
  query GetProvider {
    provider @client
  }
`;
export const GET_SIGNER = gql`
  query GetSigner {
    signer @client
  }
`;
