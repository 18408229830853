/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Mutation = {
  __typename?: 'Mutation';
  chainsultingLead: Scalars['Boolean'];
};


export type MutationChainsultingLeadArgs = {
  chain: Scalars['String'];
  chainAddress: Scalars['String'];
  companyName: Scalars['String'];
  companyWebsite: Scalars['String'];
  email: Scalars['String'];
  txHash: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkTvl: TvlResponse;
  hackenLead: Scalars['Boolean'];
  productLead: Scalars['Boolean'];
};


export type QueryHackenLeadArgs = {
  chain: Scalars['String'];
  chainAddress: Scalars['String'];
  complimentary: Scalars['Boolean'];
  email: Scalars['String'];
  txHash: Scalars['String'];
};


export type QueryProductLeadArgs = {
  blockchain?: InputMaybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactTelegram?: InputMaybe<Scalars['String']>;
  interest: Scalars['String'];
  projectName?: InputMaybe<Scalars['String']>;
  projectWebsite?: InputMaybe<Scalars['String']>;
};

export type TvlResponse = {
  __typename?: 'TvlResponse';
  market_cap: Scalars['Float'];
  tokenCount: Scalars['Int'];
};

export type HackenLeadQueryVariables = Exact<{
  email: Scalars['String'];
  chain: Scalars['String'];
  chainAddress: Scalars['String'];
  txHash: Scalars['String'];
  complimentary: Scalars['Boolean'];
}>;


export type HackenLeadQuery = { __typename?: 'Query', hackenLead: boolean };

export type ChainsultingLeadMutationVariables = Exact<{
  email: Scalars['String'];
  chain: Scalars['String'];
  chainAddress: Scalars['String'];
  txHash: Scalars['String'];
  companyname: Scalars['String'];
  companyWebsite: Scalars['String'];
}>;


export type ChainsultingLeadMutation = { __typename?: 'Mutation', chainsultingLead: boolean };


export const HackenLeadDocument = gql`
    query HackenLead($email: String!, $chain: String!, $chainAddress: String!, $txHash: String!, $complimentary: Boolean!) {
  hackenLead(
    email: $email
    chain: $chain
    chainAddress: $chainAddress
    txHash: $txHash
    complimentary: $complimentary
  )
}
    `;

/**
 * __useHackenLeadQuery__
 *
 * To run a query within a React component, call `useHackenLeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useHackenLeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHackenLeadQuery({
 *   variables: {
 *      email: // value for 'email'
 *      chain: // value for 'chain'
 *      chainAddress: // value for 'chainAddress'
 *      txHash: // value for 'txHash'
 *      complimentary: // value for 'complimentary'
 *   },
 * });
 */
export function useHackenLeadQuery(baseOptions: Apollo.QueryHookOptions<HackenLeadQuery, HackenLeadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HackenLeadQuery, HackenLeadQueryVariables>(HackenLeadDocument, options);
      }
export function useHackenLeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HackenLeadQuery, HackenLeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HackenLeadQuery, HackenLeadQueryVariables>(HackenLeadDocument, options);
        }
export type HackenLeadQueryHookResult = ReturnType<typeof useHackenLeadQuery>;
export type HackenLeadLazyQueryHookResult = ReturnType<typeof useHackenLeadLazyQuery>;
export type HackenLeadQueryResult = Apollo.QueryResult<HackenLeadQuery, HackenLeadQueryVariables>;
export const ChainsultingLeadDocument = gql`
    mutation chainsultingLead($email: String!, $chain: String!, $chainAddress: String!, $txHash: String!, $companyname: String!, $companyWebsite: String!) {
  chainsultingLead(
    email: $email
    chain: $chain
    chainAddress: $chainAddress
    txHash: $txHash
    companyName: $companyname
    companyWebsite: $companyWebsite
  )
}
    `;
export type ChainsultingLeadMutationFn = Apollo.MutationFunction<ChainsultingLeadMutation, ChainsultingLeadMutationVariables>;

/**
 * __useChainsultingLeadMutation__
 *
 * To run a mutation, you first call `useChainsultingLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChainsultingLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chainsultingLeadMutation, { data, loading, error }] = useChainsultingLeadMutation({
 *   variables: {
 *      email: // value for 'email'
 *      chain: // value for 'chain'
 *      chainAddress: // value for 'chainAddress'
 *      txHash: // value for 'txHash'
 *      companyname: // value for 'companyname'
 *      companyWebsite: // value for 'companyWebsite'
 *   },
 * });
 */
export function useChainsultingLeadMutation(baseOptions?: Apollo.MutationHookOptions<ChainsultingLeadMutation, ChainsultingLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChainsultingLeadMutation, ChainsultingLeadMutationVariables>(ChainsultingLeadDocument, options);
      }
export type ChainsultingLeadMutationHookResult = ReturnType<typeof useChainsultingLeadMutation>;
export type ChainsultingLeadMutationResult = Apollo.MutationResult<ChainsultingLeadMutation>;
export type ChainsultingLeadMutationOptions = Apollo.BaseMutationOptions<ChainsultingLeadMutation, ChainsultingLeadMutationVariables>;