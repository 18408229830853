import { Form } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Input from "antd/lib/input";
import hacken from "../../assets/images/hacken-io-logo.svg";

const HackenLead = ({ isEmail }: { isEmail: RegExp }) => {
  return (
    <div>
      <div className="green-title-container">
        <h3 className="gradient-background-text">Automated </h3>
        <h3>Audit by Hacken</h3>
      </div>
      <div className="automated-audit-container content-box">
        <img className="ethereum-coin-img" src={hacken} alt="hacken" />
        <p>
          Reap the benefits of our partnerships - audit your minted tokens with
          Hacken for $4000 instead of the original $7000 price tag.
        </p>
        <p className="hacken-audit-info">
          All audits are carried out by Hacken themselves. They will get in
          touch with you directly via email. Note, KYC required.
        </p>
        <div>
          <Form.Item name="complimentary" valuePropName="checked">
            <Checkbox>Discounted Audit Report by Hacken</Checkbox>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.complimentary !== currentValues.complimentary
            }
          >
            {({ getFieldValue, resetFields }) => {
              !getFieldValue("complimentary") && resetFields(["emailReport"]);
              return getFieldValue("complimentary") ? (
                <div className="email-report">
                  <label>
                    Please enter your email to receive the audit report
                  </label>
                  <Form.Item
                    name="emailReport"
                    rules={[
                      {
                        required: true,
                        message: "Email is not valid!",
                        pattern: isEmail,
                      },
                    ]}
                  >
                    <Input size="large"></Input>
                  </Form.Item>
                </div>
              ) : null;
            }}
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default HackenLead;
