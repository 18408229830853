import { Form } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Input from "antd/lib/input";
import { useState } from "react";
import chainsulting from "../../assets/images/chainsulting-logo.svg";
import styles from "./styles.module.scss";
const ChainsultingLead = ({
  isEmail,
  isValidUrl,
}: {
  isEmail: RegExp;
  isValidUrl: RegExp;
}) => {
  const [isChainsultingLead, setIsChainsultingLead] = useState(false);

  return (
    <div>
      <div className="green-title-container">
        <h3 className="gradient-background-text">Additional </h3>
        <h3>Audit by Chainsulting</h3>
      </div>
      <div
        className={`${styles.chainsultingContainer}  ${styles.mainContent} content-box`}
      >
        <div>
          <div className={styles.descriptionContainer}>
            <p>
              Request for an audit from our partner Chainsulting for a
              discounted price of $6000. Note, KYC and approval from
              Chainsulting is required in order to get an official audit report.
            </p>
          </div>

          <Form.Item
            name="chainsultingLead"
            valuePropName="checked"
            style={{ marginBottom: "20px" }}
          >
            <Checkbox
              onChange={() => setIsChainsultingLead(!isChainsultingLead)}
            >
              Request Discounted Audit Report by Chainsulting
            </Checkbox>
          </Form.Item>
          <div
            style={
              isChainsultingLead ? { display: "block" } : { display: "none" }
            }
          >
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.chainsultingLead !== currentValues.chainsultingLead
              }
              style={{ marginBottom: "10px" }}
            >
              {({ getFieldValue, resetFields }) => {
                !getFieldValue("chainsultingLead") &&
                  resetFields([
                    "chainsultingLeadWebsite",
                    "chainsultingLeadEmail",
                    "chainsultingLeadCompanyName",
                  ]);
                return getFieldValue("chainsultingLead") ? (
                  <div className="email-report">
                    <label>Please enter your website address</label>
                    <Form.Item
                      name="chainsultingLeadWebsite"
                      rules={[
                        {
                          required: true,
                          message: "Website is not valid!",
                          pattern: isValidUrl,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Your website address"
                      ></Input>
                    </Form.Item>
                  </div>
                ) : null;
              }}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.chainsultingLead !== currentValues.chainsultingLead
              }
              style={{ marginBottom: "10px" }}
            >
              {({ getFieldValue, resetFields }) => {
                !getFieldValue("chainsultingLead") &&
                  resetFields([
                    "chainsultingLeadWebsite",
                    "chainsultingLeadEmail",
                    "chainsultingLeadCompanyName",
                  ]);
                return getFieldValue("chainsultingLead") ? (
                  <div className="email-report">
                    <label>Please enter your legal company name</label>
                    <Form.Item
                      name="chainsultingLeadCompanyName"
                      rules={[
                        {
                          required: true,
                          message: "Company is required!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Your legal company name"
                      ></Input>
                    </Form.Item>
                  </div>
                ) : null;
              }}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.chainsultingLead !== currentValues.chainsultingLead
              }
              style={{ marginBottom: "10px" }}
            >
              {({ getFieldValue, resetFields }) => {
                !getFieldValue("chainsultingLead") &&
                  resetFields([
                    "chainsultingLeadWebsite",
                    "chainsultingLeadEmail",
                    "chainsultingLeadCompanyName",
                  ]);
                return getFieldValue("chainsultingLead") ? (
                  <div className="email-report">
                    <label>Please enter your email</label>
                    <Form.Item
                      name="chainsultingLeadEmail"
                      rules={[
                        {
                          required: true,
                          message: "Email is not valid!",
                          pattern: isEmail,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Your email address"
                      ></Input>
                    </Form.Item>
                  </div>
                ) : null;
              }}
            </Form.Item>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <img src={chainsulting} alt="hacken" />
        </div>
      </div>
    </div>
  );
};

export default ChainsultingLead;
