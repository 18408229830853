// IMAGES
import notFoundImg from "../assets/images/not-found.png";
import Header from "../Minter/Header";
import Footer from "../shared/Footer";

// STYLES
import "./styles.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <Header />
      <div className="not-found-content-container">
        <div className="content-box">
          <img src={notFoundImg} alt="" />
          <h2 className="not-found">Not Found</h2>
        </div>
        <Footer faqs={false} />
      </div>
    </div>
  );
};

export default NotFound;
