import Button from "antd/lib/button";
import metamask from "../../assets/images/metamask.svg";
import { GetAccountVar } from "../../state";
import { formatAddress } from "../../utils/format-address";

const ConnectMetamask = ({
  activateBrowserWallet,
}: {
  account: string | null | undefined;
  activateBrowserWallet: () => void;
}): JSX.Element | null => {
  const { account } = GetAccountVar();
  if (!account) {
    return (
      <div className="metamask-container content-box">
        <img src={metamask} alt="metamask" />
        <h5>Get started by connecting with Web3/Metamask!</h5>
        {window.ethereum ? (
          <Button
            onClick={() => activateBrowserWallet()}
            type="primary"
            size="large"
          >
            {account ? (
              <span className="account-number">
                {formatAddress({ address: account })}
              </span>
            ) : (
              "Connect with Metamask"
            )}
          </Button>
        ) : (
          <Button type="primary" size="large" disabled>
            Wallet not detected
          </Button>
        )}
        <div className="info-text-container">
          <p className="info-text">
            You’ll need to confirm the transaction using Web3/Metamask. <br />
            Please connect to get started.
          </p>
        </div>
      </div>
    );
  }
  return null;
};

export default ConnectMetamask;
