import { useEffect } from "react";
import { useEthers } from "../../hooks/useEthersCustom";

// STATE
import { setAccountState, setChainState } from "../../state";

// ANTD
import Button from "antd/lib/button";

// STYLES
import "./styles.scss";

// IMAGES
import logo from "../../assets/images/lossless-minter-icon-white.svg";
import userIcon from "../../assets/images/user-wallet.svg";

// UTILS
import { formatAddress } from "../../utils/format-address";
import { getChain } from "../../utils/get-chain";
import { gaEvent } from "../../utils/ga";

const TransparentHeader = () => {
  const { activateBrowserWallet, account, chainId } = useEthers();

  const connectWallet = () => {
    gaEvent("Connected Wallet");
    activateBrowserWallet();
  };

  useEffect(() => {
    setAccountState(account || undefined);
  }, [account]);

  useEffect(() => {
    if (chainId) setChainState(getChain(chainId));
  }, [chainId]);

  return (
    <div className="transparent-header-container">
      <div className="transparent-header-content-container">
        <div className="logo-container">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="menu-container">
          <a
            href="https://docs.lossless.io/protocol/technical-reference/lerc20"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </div>
        <div className="connect-container">
          <Button
            onClick={connectWallet}
            type="primary"
            size="large"
            className="connect-button"
            icon={<img className="user-icon" src={userIcon} alt="User Icon" />}
          >
            {account ? (
              <span className="account-number">
                {formatAddress({ address: account })}
              </span>
            ) : (
              "Connect Wallet"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransparentHeader;
