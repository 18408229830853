import React, { useEffect } from "react";
import { useHistory } from "react-router";

// COMPONENTS
import Footer from "../../shared/Footer";

// STYLES
import "./styles.scss";

// UTILS
import { formatAddress } from "../../utils/format-address";

// IMAGES
import checkCircle from "../../assets/images/check-circle.svg";

// INTERFACES
import { Chain } from "../../config/networks";
import Header from "../Header";

const Success = (props: any) => {
  const history = useHistory();

  const chain: Chain = props.history.location.state?.chain;
  const transactionHash: string = props.history.location.state?.transactionHash;

  if (!chain || !transactionHash) {
    history.push("");
  }

  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = (): void => {
    const element = document.getElementById("scroll-container");
    if (element && element.scrollTop) element.scrollTop = 0;
  };

  return (
    <div className="success-container">
      <Header />
      <div className="generator-container">
        <div className="token-minter-title-row">
          <h1 className="title">Token Minter</h1>
        </div>

        <div className="token-deploy-container content-box">
          <img src={checkCircle} alt="" />
          <h1>A new Lossless protected token was deployed.</h1>
          <span>
            Review transaction on{" "}
            <a
              href={chain?.explorer + "tx/" + transactionHash}
              target="_blank"
              rel="noreferrer"
            >
              {formatAddress({ address: transactionHash, fullSize: true })}
            </a>
          </span>
        </div>
        <Footer faqs={false} />
      </div>
    </div>
  );
};

export default Success;
