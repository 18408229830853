import { useHistory } from "react-router-dom";

// ANTD
import Button from "antd/lib/button";

// COMPONENTS
import Faq from "../shared/Faq";

// STYLES
import "./styles.scss";

// IMAGES
import arrowRight from "../assets/images/arrow-right.svg";
import hackMitigating from "../assets/images/hack-mitigating.svg";
import auditReport from "../assets/images/audit-report.svg";
import verifiedCode from "../assets/images/verified-code.svg";
import compliant from "../assets/images/compliant.svg";
import Footer from "../shared/Footer";
import TransparentHeader from "./TransparentHeader";

const MINT_TOKEN = "Mint your Token";
const CREATE_TOKEN_ROUTE = "token-minter";

const Home = () => {
  const history = useHistory();

  const navigateTo = (route: string): void => {
    history.push(route);
  };

  return (
    <>
      <TransparentHeader />
      <div className="main-container">
        <div className="content">
          <h1 className="title">
            Create your own token, <br />
            <span>in just one minute</span>
          </h1>
          <p className="desc">
            Generate and Deploy tokens right from your browser.
          </p>
          <Button size="large" onClick={() => navigateTo(CREATE_TOKEN_ROUTE)}>
            {MINT_TOKEN}{" "}
            <img className="button-arrow-right" src={arrowRight} alt="" />
          </Button>
        </div>
      </div>
      <div className="features-container">
        <div>
          <div className="content-box">
            <div className="content">
              <h2>Hack-mitigating L-ERC20 Standard</h2>
              <p>
                Enjoy the limitless possibilities of your freshly-minted token
                without fear of token loss. With Lossless, your token community
                can rest assured that hack-related transactions will be spotted,
                frozen, and reversed, ensuring that no hacker ever profits from
                his exploits.
              </p>
              <Button size="large">
                <a
                  href="https://docs.lossless.io/protocol/technical-reference/lerc20"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </Button>
            </div>
            <div className="image-container">
              <img src={hackMitigating} alt="" />
            </div>
          </div>
        </div>
        <div className="blackBg">
          <div className="content-box ">
            <div className="image-container">
              <img className="audit-report" src={auditReport} alt="" />
            </div>
            <div className="content">
              <h2>Automated Audit Report by Hacken</h2>
              <p>
                Add an exclusive audit report by one of the leading smart
                contract security audit companies. For an additional $1000, you
                will receive an independent professional assessment from Hacken,
                assuring your community that there are no potential
                vulnerabilities or security flaws within the smart contract and
                that your token meets the highest level of security.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="content-box">
            <div className="content">
              <h2>Verified Code</h2>
              <p>
                Our deployed smart contracts are verified to ensure code
                integrity, security, and compliance with the Solidity Code Style
                guide, facilitating trust between counterparties.
              </p>
              <Button
                onClick={() => navigateTo(CREATE_TOKEN_ROUTE)}
                type="primary"
                size="large"
              >
                {MINT_TOKEN}
              </Button>
            </div>
            <div className="image-container">
              <img src={verifiedCode} alt="" />
            </div>
          </div>
        </div>
        <div className="blackBg">
          <div className="content-box last blackBg">
            <div className="image-container">
              <img src={compliant} alt="" />
            </div>
            <div className="content">
              <h2>ERC20/BEP20 Compliant</h2>
              <p>
                All new tokens generated using the L-ERC20 Token Minter comply
                with the ERC20 technical specification and are compatible with
                any ERC20/BEP20 wallet.
              </p>
              <Button
                onClick={() => navigateTo(CREATE_TOKEN_ROUTE)}
                type="primary"
                size="large"
              >
                {MINT_TOKEN}
              </Button>
            </div>
          </div>
        </div>
        <div className="faq-home-container">
          <Faq />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
