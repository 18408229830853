import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useCallback, useEffect, useState } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { networks } from "../config/networks";
import { Signer } from "ethers";

type ActivateBrowserWallet = (
  onError?: (error: Error) => void,
  throwErrors?: boolean
) => void;

export type Web3Ethers = ReturnType<typeof useWeb3React> & {
  library?: Web3Provider;
  chainId?: number;
  activateBrowserWallet: ActivateBrowserWallet;
  signer?: Signer;
};

export function useEthers(): Web3Ethers {
  const result = useWeb3React<Web3Provider>();

  const [chainId, setChainId] = useState<number | undefined>();

  const activateBrowserWallet = useCallback<ActivateBrowserWallet>(
    async (onError, throwErrors) => {
      const injected = new InjectedConnector({
        supportedChainIds: networks?.map((network) => parseInt(network.id, 16)),
      });
      if (onError instanceof Function) {
        await result.activate(injected, onError, throwErrors);
      } else {
        await result.activate(injected, undefined, throwErrors);
      }
    },
    [result]
  );

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && !result.active) {
      const handleChainChanged = (chainId: any) => {
        setChainId(parseInt(chainId, 16));
      };
      ethereum.on("chainChanged", handleChainChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
        }
      };
    }

    return () => {};
  }, []);

  const signer = result.library?.getSigner() as Signer;
  return { ...result, signer, chainId, activateBrowserWallet };
}
