import { Form, Select } from "antd";
import Input from "antd/lib/input";
const { Option } = Select;
const TokenOwnership = ({
  account,
  isAccount,
}: {
  account: string;
  isAccount: RegExp;
}) => {
  return (
    <div>
      <div className="green-title-container">
        <h3 className="gradient-background-text">Token </h3>
        <h3>Ownership Information</h3>
      </div>
      <div className="token-ownership-container content-box">
        <div>
          <label>Token owner</label>
          <Form.Item name="tokenOwner" rules={[{ required: true }]}>
            <Input
              className="owner"
              size="large"
              value={account ? account : ""}
              readOnly
            ></Input>
          </Form.Item>
          <p>
            The token owner’s wallet is the project owner’s main wallet, used to
            sign the smart contract transaction and receive the minted tokens.
          </p>
        </div>
        <div>
          <label>Admin Wallet</label>
          <Form.Item
            name="adminAccount"
            rules={[
              {
                required: true,
                message: "Admin wallet address is required!",
              },
              {
                message: "Admin wallet is not an address!",
                pattern: isAccount,
              },
            ]}
          >
            <Input className="owner" size="large"></Input>
          </Form.Item>
          <p>
            The admin wallet is the project owner’s administrative wallet
            address, used for interactions with the Lossless protocol and
            governance bodies.
          </p>
        </div>
        <div>
          <label>Recovery Admin Wallet</label>

          <Form.Item
            name="recoveryAccount"
            rules={[
              {
                required: true,
                message: "Recovery admin wallet address is required!",
              },
              {
                message: "Recovery admin wallet is not an address!",
                pattern: isAccount,
              },
            ]}
          >
            <Input size="large"></Input>
          </Form.Item>

          <p>
            The recovery admin wallet is the project owner’s recovery wallet
            address used to change the admin wallet. Multisignature
            authentication is advised to keep it as secure as possible.
          </p>
        </div>
        <div>
          <label>Time Lock Period</label>
          <Form.Item
            name="lockPeriod"
            rules={[
              {
                required: true,
                message: "Time lock period is required!",
              },
            ]}
          >
            <Select
              size="large"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="86400">24 hours</Option>
              <Option value="129600">36 hours</Option>
              <Option value="172800">48 hours</Option>
            </Select>
          </Form.Item>
          <p>
            Timelock period refers to the number of hours the token owner would
            have to wait after requesting Lossless protection to be turned off.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TokenOwnership;
