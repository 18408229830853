import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// COMPONENTS
import Minter from "./Minter";
import Home from "./Home";
import NotFound from "./NotFound";
import Success from "./Minter/Success";
import { useFirstTimeConnect } from "./hooks/useFirstTimeConnect";

// UTILS
import { usePageTracking } from "./hooks/usePageTracking";

// STYLES
import "./App.css";

function App() {
  usePageTracking();
  useFirstTimeConnect();

  return (
    <div className="App">
      <div className="content-container" id="scroll-container">
        <Router>
          <Switch>
            <Route path={"/"} exact={true} component={Home} />
            <Route path={"/token-minter"} exact={true} component={Minter} />
            <Route
              path={"/token-minter/success"}
              exact={true}
              component={Success}
            />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
