import ethereumIcon from "../assets/images/eth.svg";
import binanceIcon from "../assets/images/bnb.svg";
import polygonIcon from "../assets/images/polygon.svg";
import harmonyIcon from "../assets/images/harmonyIcon.svg";
import avaxIcon from "../assets/images/avalanche.svg";
import fantomIcon from "../assets/images/fantom.svg";
import elysiumIcon from "../assets/images/elysium.svg";
import arbitrumIcon from "../assets/images/arbitrum.svg";
import saakuruIcon from "../assets/images/saakuru.svg";
import baseIcon from "../assets/images/base.png";
import optimismIcon from "../assets/images/optimism.svg";

export interface Chain {
  id: string;
  name: string;
  controller: string;
  icon: string;
  explorer: string;
}

export const eth: Chain = {
  id: "0x1",
  name: "Ethereum Network",
  controller: "0xe91D7cEBcE484070fc70777cB04F7e2EfAe31DB4",
  icon: ethereumIcon,
  explorer: "https://etherscan.io/",
};

export const rin: Chain = {
  id: "0x4",
  name: "Rinkeby Network",
  controller: "0xb32aa4B6731Ba281A84E34757E41B72f2C090A44",
  icon: ethereumIcon,
  explorer: "https://rinkeby.etherscan.io/",
};

export const bsc: Chain = {
  id: "0x38",
  name: "Binance Smart Chain",
  controller: "0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634",
  icon: binanceIcon,
  explorer: "https://bscscan.com/",
};

export const sct: Chain = {
  id: "0x61",
  name: "Smart Chain - Testnet",
  controller: "0xC46236F780f1294B2C2a8c3cE5B4d62258dC2619",
  icon: binanceIcon,
  explorer: "https://testnet.bscscan.com/",
};

export const plg: Chain = {
  id: "0x89",
  name: "Polygon Network",
  controller: "0x66622e2C1b991983e88132da19b2C31f71009035",
  icon: polygonIcon,
  explorer: "https://polygonscan.com/",
};

export const mbi: Chain = {
  id: "0x13881",
  name: "Matic Mumbai",
  controller: "0x29C2541d25091B565c6999f20549EB2c75b7c269",
  icon: polygonIcon,
  explorer: "https://mumbai.polygonscan.com/",
};

export const hmy: Chain = {
  id: "0x63564c40",
  name: "Harmony",
  controller: "0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634",
  icon: harmonyIcon,
  explorer: "https://explorer.harmony.one/",
};

export const hmt: Chain = {
  id: "0x6357d2e0",
  name: "Harmony Testnet",
  controller: "0xe91D7cEBcE484070fc70777cB04F7e2EfAe31DB4",
  icon: harmonyIcon,
  explorer: "https://explorer.pops.one/",
};

export const ftm: Chain = {
  id: "0xfa",
  name: "Fantom",
  controller: "0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634",
  icon: fantomIcon,
  explorer: "https://ftmscan.com/",
};

export const ftmt: Chain = {
  id: "0xfa2",
  name: "Fantom Testnet",
  controller: "0xBEDba51A30CD4c8d708E038943dE0b3f0A089776",
  icon: fantomIcon,
  explorer: "https://testnet.ftmscan.com/",
};

export const avax: Chain = {
  id: "0xa86a",
  name: "Avalanche",
  controller: "0xe5b5753cFEaD850f7069b05B3A76645Af63A9102",
  icon: avaxIcon,
  explorer: "https://snowtrace.io/",
};

export const avxt: Chain = {
  id: "0xa869",
  name: "Avalanche FUJI",
  controller: "0xF601afcc4A2a1f991bf46AE37Fa87C9732aBbF84",
  icon: avaxIcon,
  explorer: "https://testnet.snowtrace.io/",
};

export const elysium: Chain = {
  id: "0x053B",
  name: "Elysium",
  controller: "0xF2abb5252fed45460F1632B2420ADf8288fb6468",
  icon: elysiumIcon,
  explorer: "https://explorer.elysiumchain.tech/",
};

export const elysiumTestnet: Chain = {
  id: "0x053A",
  name: "Elysium Testnet",
  controller: "0xe84053152Ac90241bce29D34E4Cdea4f47e8a13D",
  icon: elysiumIcon,
  explorer: "https://elysium-explorer.vulcanforged.com/",
};

export const goerliTestnet: Chain = {
  id: "0x5",
  name: "Goerli",
  controller: "0xf88a2B5270C3A209ff434e5f105045Da1E997dbf",
  icon: ethereumIcon,
  explorer: "https://goerli.etherscan.io/",
};

export const arb: Chain = {
  id: "0xa4b1",
  name: "Arbitrum Network",
  controller: "0x38D40f5c8375F84C5B90bc460A94a436D09133fB",
  icon: arbitrumIcon,
  explorer: "https://arbiscan.io/",
};

export const saakuru: Chain = {
  id: "0x6e4216",
  name: "Saakuru Network",
  controller: "0x38D40f5c8375F84C5B90bc460A94a436D09133fB",
  icon: saakuruIcon,
  explorer: "https://explorer.saakuru.network/",
};

export const base: Chain = {
  id: "0x2105",
  name: "Base",
  controller: "0x38D40f5c8375F84C5B90bc460A94a436D09133fB",
  icon: baseIcon,
  explorer: "https://basescan.org/",
};

export const optimism: Chain = {
  id: "0xa",
  name: "Optimism",
  controller: "0x38D40f5c8375F84C5B90bc460A94a436D09133fB",
  icon: optimismIcon,
  explorer: "https://basescan.org/",
};

export const networks = [
  eth,
  goerliTestnet,
  bsc,
  sct,
  plg,
  mbi,
  hmy,
  hmt,
  ftm,
  ftmt,
  avax,
  avxt,
  elysium,
  elysiumTestnet,
  arb,
  saakuru,
  base,
  optimism,
];

export const mintableBurnableChains = [
  eth.id,
  goerliTestnet.id,
  bsc.id,
  sct.id,
  ftmt.id,
  ftm.id,
  elysium.id,
  elysiumTestnet.id,
  arb.id,
  saakuru.id,
  base.id,
  optimism.id,
];
