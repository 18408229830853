import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Contract, BigNumber } from "ethers";
import { useEthers } from "../../../hooks/useEthersCustom";

// ANTD
import { Button, FormInstance } from "antd";

// GRAPHQL
import {
  useHackenLeadLazyQuery,
  useChainsultingLeadMutation,
} from "../../../graphql/generated/graphql";

// STYLES
import "../styles.scss";

// UTILS
import { CustomDataJSON, deployContract } from "../../../utils/deploy-contract";
import { formatAddress } from "../../../utils/format-address";

// INTERFACES
import { Chain, mintableBurnableChains } from "../../../config/networks";

import {
  ERROR,
  ERROR_MODAL_TITLE,
  HACKEN_LEAD,
  LOADING,
  CHAINSULTING_LEAD,
} from "../../../constants";
// ABI
import LERC20 from "../../../abis/LERC20.json";
import LERC20Mintable from "../../../abis/LERC20Mintable.json";
import LERC20Burnable from "../../../abis/LERC20Burnable.json";
import LERC20MintableBurnable from "../../../abis/LERC20MintableBurnable.json";

import { deploymentGaEvent, gaEvent } from "../../../utils/ga";

const ModalContent = ({
  state,
  chain,
  form,
  setDeployState,
  hideModal,
  setModalTitle,
}: {
  state: string;
  chain: Chain | null;
  form: FormInstance<any>;
  setDeployState: React.Dispatch<React.SetStateAction<string>>;
  hideModal: () => void;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element | null => {
  const history = useHistory();
  const { signer } = useEthers();
  const [transactionHash, setTransactionHash] = useState("");
  const [hackenLead, hackenResponse] = useHackenLeadLazyQuery();
  const [chainsulting, chainsultingResponse] = useChainsultingLeadMutation();
  const tokenOwner = form.getFieldValue("tokenOwner");
  const tokenName = form.getFieldValue("tokenName");
  const tokenSymbol = form.getFieldValue("tokenSymbol");
  const initialSupply = form.getFieldValue("initialSupply");
  const adminAccount = form.getFieldValue("adminAccount");
  const recoveryAccount = form.getFieldValue("recoveryAccount");
  const lockPeriod = form.getFieldValue("lockPeriod");
  const emailReport = form.getFieldValue("emailReport");
  const complimentary = form.getFieldValue("complimentary");
  const chainsultingLeadWebsite = form.getFieldValue(
    "chainsultingLeadWebsite"
  ) as string;
  const chainsultingLeadCompanyName = form.getFieldValue(
    "chainsultingLeadCompanyName"
  ) as string;
  const chainsultingLeadEmail = form.getFieldValue(
    "chainsultingLeadEmail"
  ) as string;

  const chainsultingLead = form.getFieldValue("chainsultingLead") as boolean;

  let mintable = form.getFieldValue("mintable");
  let burnable = form.getFieldValue("burnable");

  const canBeMintableBurnable =
    chain &&
    mintableBurnableChains
      .map((el) => parseInt(el))
      .includes(parseInt(chain.id.toString()));

  if (!canBeMintableBurnable) {
    mintable = false;
    burnable = false;
  }

  const deploy = async () => {
    if (
      !form.getFieldsError().filter((err) => err.errors.length > 0).length &&
      chain &&
      signer
    ) {
      setDeployState(LOADING);

      try {
        const initialSupplyFormated = BigNumber.from(initialSupply).mul(
          BigNumber.from([10]).pow(18)
        );

        gaEvent("Confirm And Deploy", `Token name: ${tokenName}`);

        let jsonData;

        if (mintable && burnable) {
          jsonData = LERC20MintableBurnable;
        } else if (mintable) {
          jsonData = LERC20Mintable;
        } else if (burnable) {
          jsonData = LERC20Burnable;
        } else {
          jsonData = LERC20;
        }

        const contractJson: CustomDataJSON = {
          abi: jsonData.abi,
          bytecode: jsonData.bytecode,
        };

        const contract = await deployContract(
          initialSupplyFormated,
          tokenName,
          tokenSymbol,
          adminAccount,
          recoveryAccount,
          lockPeriod,
          signer,
          chain.controller,
          contractJson
        );
        if (complimentary && chain) {
          setDeployState(HACKEN_LEAD);
          await sendHackenLead(chain, contract);
        }
        if (chainsultingLead && chain) {
          setDeployState(CHAINSULTING_LEAD);
          await sendChainsultingLead(chain, contract);
        }
        setTimeout(() => {
          setDeployState(LOADING);
        }, 3000);

        await contractDeployed(contract);
        deploymentGaEvent(contract, chain.name);
        setTransactionHash(contract.deployTransaction.hash);
      } catch (error) {
        console.log(error);
        setDeployState(ERROR);
        setModalTitle(ERROR_MODAL_TITLE);
      }
    }
  };

  const sendHackenLead = async (
    chain: Chain,
    deployed: Contract
  ): Promise<boolean> => {
    try {
      await hackenLead({
        variables: {
          email: emailReport,
          chain: chain?.name,
          chainAddress: chain?.explorer,
          txHash: deployed.deployTransaction.hash,
          complimentary,
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const sendChainsultingLead = async (
    chain: Chain,
    deployed: Contract
  ): Promise<boolean> => {
    try {
      await chainsulting({
        variables: {
          email: chainsultingLeadEmail,
          chain: chain?.name,
          chainAddress: chain?.explorer,
          txHash: deployed.deployTransaction.hash,
          companyname: chainsultingLeadCompanyName,
          companyWebsite: chainsultingLeadWebsite,
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const contractDeployed = async (contract: Contract): Promise<void> => {
    try {
      await contract.deployed();
    } catch (error) {
      setDeployState(ERROR);
      setModalTitle(ERROR_MODAL_TITLE);
    }
  };

  const listItems = [
    { label: "Supported Network", content: chain?.name },
    {
      label: "Mintable",
      content: mintable ? "YES" : "NO",
    },
    {
      label: "Burnable",
      content: burnable ? "YES" : "NO",
    },
    { label: "Token Name", content: tokenName },
    { label: "Token Symbol Ticker", content: tokenSymbol },
    { label: "Token Supply", content: initialSupply },
    {
      label: "Token Owner",
      content: formatAddress({ address: tokenOwner, fullSize: true }),
    },
    {
      label: "Admin Wallet",
      content: formatAddress({ address: adminAccount, fullSize: true }),
    },
    {
      label: "Recovery Admin Wallet",
      content: formatAddress({ address: recoveryAccount, fullSize: true }),
    },
    { label: "Time Lock Period", content: `${lockPeriod / 3600}h` },
    {
      label: "Complimentary audit report",
      content: complimentary ? "YES" : "NO",
    },
    emailReport && {
      label: "Email for Hacked",
      content: emailReport,
    },
    {
      label: "Chainsulting Audit",
      content: chainsultingLead ? "YES" : "NO",
    },
    chainsultingLead && {
      label: "Website for Chainsulting",
      content: chainsultingLeadWebsite,
    },

    chainsultingLead && {
      label: "Company name for Chainsulting",
      content: chainsultingLeadCompanyName,
    },

    chainsultingLead && {
      label: "Email for Chainsulting",
      content: chainsultingLeadEmail,
    },
  ];

  useEffect(() => {
    if (
      !hackenResponse.loading &&
      !chainsultingResponse.loading &&
      transactionHash
    ) {
      history.push({
        pathname: "/token-minter/success",
        state: {
          chain,
          transactionHash: transactionHash,
        },
      });
    } else if (
      !hackenResponse.loading &&
      !chainsultingResponse.loading &&
      transactionHash
    ) {
      history.push({
        pathname: "/token-minter/success",
        state: {
          chain,
          transactionHash: transactionHash,
        },
      });
    }
  }, [transactionHash]);

  return state === "CONFIRM" ? (
    <div className="modal-content-container">
      <div>
        <ul>
          {listItems.map((item) => {
            if (item)
              return (
                <li key={item.label}>
                  <label>{item.label}</label>
                  <span className="content-info-item">{item.content}</span>
                </li>
              );
          })}
        </ul>
      </div>
      <div className="deploy-buttons-container">
        <Button size="large" onClick={() => hideModal()}>
          Cancel
        </Button>
        <Button type="primary" size="large" onClick={() => deploy()}>
          Confirm and deploy
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalContent;
