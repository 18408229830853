// STYLES
import "./styles.scss";

// IMAGES
import logo from "../../assets/images/lossless-minter-icon.svg";
import { useEffect } from "react";

const Footer = ({ faqs = true }: { faqs?: boolean }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://crypto.com/price/static/widget/index.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="footer-container">
      <div className="logo-container">
        <a href="/">
          <img src={logo} alt="Logo" />
        </a>
        <span className="copyright">&#169;2024 Lossless</span>
      </div>
      <div className="widget-container">
        <div
          id="crypto-widget-CoinList"
          data-transparent="true"
          data-design="classic"
          data-coin-ids="4657"
        ></div>
      </div>
      <div className="footer-items">
        {faqs ? (
          <a
            href="https://docs.lossless.io/protocol/faq"
            target="_blank"
            rel="noreferrer"
          >
            FAQs
          </a>
        ) : null}
        <a
          href="https://lossless.io/terms-of-use/"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        <a href="mailto: hello@lossless.cash">Contact Us</a>
      </div>
    </div>
  );
};

export default Footer;
