// ANTD
import { Form, Radio } from "antd";

import Input from "antd/lib/input";
import { Chain, networks } from "../../config/networks";
import avaxIcon from "../../assets/images/avalanche.svg";
// ICONS
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";

const SupportedNetworks = ({
  chain,
  handleNetworkChange,
}: {
  chain: Chain | null | undefined;
  handleNetworkChange: (event: any) => void;
}) => {
  return (
    <div className="supported-networks-container content-box">
      <div className="selected-network-container">
        <Form.Item name="account">
          <Input
            prefix={<img className="selected-network-icon" src={chain?.icon} />}
            size="large"
            readOnly
          ></Input>
        </Form.Item>
        <span className="selected-network">
          {chain ? (
            chain?.name
          ) : (
            <>
              <ExclamationCircleOutlined />
              &nbsp;Connect your wallet to a supported network
            </>
          )}
        </span>
      </div>
      <h5 className="supported-networks-subtitle">Supported networks</h5>
      <div className="supported-networks-btn-container">
        <Radio.Group size="large" value={chain} onChange={handleNetworkChange}>
          {networks.map((network) => {
            return (
              <Radio.Button
                value={network}
                className="btn-network"
                type="primary"
                key={network.id}
              >
                <img src={network.icon} alt={network.name} />
                {network.name}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    </div>
  );
};

export default SupportedNetworks;
