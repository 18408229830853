import { makeVar, useQuery } from "@apollo/client";
import { Signer } from "ethers";

// GRAPHQL
import {
  GET_ACCOUNT,
  GET_CHAIN,
  GET_SIGNER,
} from "./graphql/queries/cache-queries";

// UTILS
import { getChain } from "./utils/get-chain";

// INTERFACES
import { Chain } from "./config/networks";

declare global {
  interface Window {
    ethereum: any;
  }
}

let initialChainState: Chain | null = null;
let initialAccountState: string | undefined = undefined;
let initialSignerState: Signer | null = null;

export const accountVar = makeVar<string | undefined>(initialAccountState);
export const chainVar = makeVar<Chain | null>(initialChainState);
export const signerVar = makeVar<Signer | null>(initialSignerState);

export const setChainState = (chain: Chain | null): void => {
  chainVar(chain);
};

export const setAccountState = (account: string | undefined): void => {
  accountVar(account);
};

export const setSignerState = (signer: Signer | null): void => {
  signerVar(signer);
};

export const GetAccountVar = (): {
  account: string;
  setAccountState: (account: string) => void;
} => {
  const accountResponse = useQuery(GET_ACCOUNT);
  const account = accountResponse.data?.account || initialAccountState;
  return { account, setAccountState };
};

export const GetChainVar = (): {
  chain: Chain | null;
  setChainState: (chain: Chain | null) => void;
} => {
  const chainResponse = useQuery(GET_CHAIN);
  const chain =
    getChain(chainResponse.data?.chain) || getChain(window.ethereum?.chainId);
  return { chain, setChainState };
};

export const GetSignerVar = (): {
  signer: Signer | null;
  setSignerState: (signer: Signer | null) => void;
} => {
  const signerResponse = useQuery(GET_SIGNER);
  const signer = signerResponse.data?.signer || initialSignerState;
  return { signer, setSignerState };
};
