import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if ((window as any)._env_?.REACT_APP_GA_TAG) {
      console.log("GA initialized: ", (window as any)._env_?.REACT_APP_GA_TAG);
      ReactGA.initialize((window as any)._env_.REACT_APP_GA_TAG);
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
};
