import { ChangeEvent, useEffect, useState } from "react";
import { useEthers } from "../hooks/useEthersCustom";

// STATE
import { GetAccountVar, GetChainVar } from "../state";

// ANTD
import { Form } from "antd";
import Button from "antd/lib/button";

// COMPONENTS
import DeployModal from "./DeployModal";
import Faq from "../shared/Faq";
import Footer from "../shared/Footer";

// STYLES
import "./styles.scss";

// NETWORKS
import { mintableBurnableChains, networks } from "../config/networks";
import ConnectMetamask from "./ConnectMetamask";
import SupportedNetworks from "./SupportedNetworks";
import TokenDetails from "./TokenDetails";
import TokenOwnership from "./TokenOwnership";

// LEAD BLOCKS
import HackenLead from "./HackenLead";
import ChainsultingLead from "./ChainsultingLead";
import { formatAddress } from "../utils/format-address";
import Header from "./Header";

declare global {
  interface Window {
    ethereum: any;
  }
}

const Minter = () => {
  const { activateBrowserWallet } = useEthers();
  const { account } = GetAccountVar();
  const { chain, setChainState } = GetChainVar();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [canBeMintableBurnable, setCanBeMintableBurnable] = useState(false);
  // ETH ACCOUNT REGEX
  const isAccount = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  // EMAIL ACCOUNT REGEX
  const isEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const isValidURL = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );

  const [form] = Form.useForm();

  const scrollTop = (): void => {
    const element = document.getElementById("scroll-container");
    if (element && element.scrollTop) element.scrollTop = 0;
  };

  const showModal = async (): Promise<void> => {
    form.setFieldsValue({ selectedNetwork: chain?.controller });
    await form.validateFields();
    if (!form.getFieldsError().filter((err) => err.errors.length > 0).length) {
      setIsModalVisible(true);
    }
  };
  const handleNetworkChange = (event: any): void => {
    setChainState(event.target.value);
  };

  const onInputNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = e.target.value.replace(/\D/g, "");
    let parseSupply = cleanedValue ? BigInt(cleanedValue) : null;
    if (parseSupply) {
      const maxSupply = 115792089237316195423570985008687907853269984665640564039457;
      if (parseSupply > BigInt(maxSupply)) {
        form.setFieldsValue({
          initialSupply:
            "115792089237316195423570985008687907853269984665640564039457",
        });
      } else {
        form.setFieldsValue({ initialSupply: parseSupply.toString() });
      }
      return;
    } else {
      form.setFieldsValue({ initialSupply: cleanedValue });
    }
  };

  const chainIdUnknown =
    !chain || !chain.id || !networks.map((net) => net.id).includes(chain.id);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      account: account,
      tokenOwner: account,
      complimentary: false,
      selectedNetwork: chain?.controller,
    });
  }, [account]);

  useEffect(() => {
    if (chain) {
      const mintableBurnable =
        chain &&
        mintableBurnableChains
          .map((el) => parseInt(el))
          .includes(parseInt(chain.id.toString()));

      if (!mintableBurnable) {
        form.setFieldsValue({
          mintable: false,
          burnable: false,
        });
      }
      setCanBeMintableBurnable(mintableBurnable);
    }
  }, [chain, form, setCanBeMintableBurnable]);

  return (
    <>
      <DeployModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        form={form}
        chain={chain}
      ></DeployModal>
      <Header />
      <div className="generator-container">
        <div className="token-minter-title-row">
          <h1 className="title">Token Minter</h1>
          {window.ethereum ? (
            <Button
              onClick={() => activateBrowserWallet()}
              type="primary"
              size="large"
            >
              {account ? (
                <span className="account-number">
                  {formatAddress({ address: account })}
                </span>
              ) : (
                "Connect with Metamask"
              )}
            </Button>
          ) : (
            <Button type="primary" size="large" disabled>
              Wallet not detected
            </Button>
          )}
        </div>
        <ConnectMetamask
          account={account}
          activateBrowserWallet={activateBrowserWallet}
        />
        <Form form={form}>
          <SupportedNetworks
            chain={chain}
            handleNetworkChange={handleNetworkChange}
          />
          <TokenDetails
            onInputNumberChange={onInputNumberChange}
            canBeMintableBurnable={canBeMintableBurnable}
          />
          <TokenOwnership account={account} isAccount={isAccount} />
          <HackenLead isEmail={isEmail} />
          <ChainsultingLead isValidUrl={isValidURL} isEmail={isEmail} />

          <div className="deploy-container content-box">
            <h3>Ready to deploy?</h3>
            <p>
              Please review the costs and the information you have provided
              before deploying.
            </p>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  onClick={() => showModal()}
                  disabled={
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0 ||
                    chainIdUnknown ||
                    !account
                  }
                >
                  Deploy
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
        <Faq />
        <Footer />
      </div>
    </>
  );
};

export default Minter;
