export const CONFIRM = "CONFIRM";
export const CONFIRMATION_MESSAGE = "Your token was deployed succesfully!";
export const CONFIRM_MODAL_TITLE = "Please confirm provided details";
export const ERROR = "ERROR";
export const ERROR_MESSAGE = "There was an issue deploying your token!";
export const ERROR_MODAL_TITLE = "Deployment failed!";
export const HACKEN_LEAD = "HACKEN_LEAD";
export const CHAINSULTING_LEAD = "CHAINSULTING_LEAD";
export const LOADING = "LOADING";
export const VERIFYING_SOURCE = "VERIFYING_SOURCE";
export const PLEASE_CONFIRM = "Please confirm provided details";
